@import "styles/colors.scss";
@import "styles/variables.scss";

.login-page {
  .forgot-password-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .forgot-password {
      color: var(--primary, #6266ef);
      text-decoration: none;
    }
  }
}

.tecma-modal-container {
  .tecma-modal {
    color: #3b3b3b;
    font-family: Lato;
    &-header {
      align-items: center;
      h2 {
        font-size: 1.25rem;
        margin: 0;
      }
      .tecma-button {
        .tecma-icon path {
          fill: #3b3b3b;
        }
      }
    }
    &-content {
      padding: 1.5rem;
      h3 {
        margin: 0;
        font-size: 1.125rem;
      }
      p {
        font-size: 0.875rem;
        color: #767676;
      }
    }
    .divider {
      border-top: 1px solid #e0e0e0;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    a.modal-link {
      text-decoration: none;
      color: var(--primary, #6266ef);
    }
  }
}
