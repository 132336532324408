@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

$general: #fff;
$general-border: #e0e0e0;
$on-general: #363b4c;
$accent-hover: #4e51c0;
$accent-active: #4e51c0;
$focus: #0000ff4d;

.tecma-drawer {
  .tecma-drawer-avatar .drawer-avatar-title,
  .tecma-drawerHeader {
    font-size: 1.125rem;
  }
}

.tecma-header {
  .tecma-header-utility-menu {
    .tecma-header-item {
      &.support-cta {
        background-color: $general;
        border: 1px solid $general-border;
        color: $on-general;
        transition: color 0.25s ease, border-color 0.25s ease;
        .tecma-icon path {
          fill: $on-general;
          transition: fill 0.25s ease;
        }
        &:hover {
          color: $accent-hover;
          border-color: $accent-hover;
          .tecma-icon path {
            fill: $accent-hover;
          }
        }
        &:active {
          color: $accent-active;
          border-color: $accent-active;
          .tecma-icon path {
            fill: $accent-active;
          }
        }
        &:focus {
          outline: solid 0.125rem $focus;
        }
      }
    }
  }
}
