@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.service-status-tag {
  font-size: $SMALL_FONT;
  font-weight: $MEDIUM;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-bottom: 0;
  &.available {
    color: $SUCCESS;
    .tecma-icon path {
      fill: $SUCCESS;
    }
  }
  &.maintenance {
    color: $WARNING;
    .tecma-icon path {
      fill: $WARNING;
    }
  }
  &.unavailable {
    color: $ERROR;
    .tecma-icon path {
      fill: $ERROR;
    }
  }
  &.comingSoon {
    color: $INFO;
    .tecma-icon path {
      fill: $INFO;
    }
  }
}
