@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@font-face {
  font-family: foco;
  src: url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Ivy Journal";
  src: url("./fonts/IvyJournal/IvyJournal-Light.ttf");
  src: local("Ivy Journal Light"), local("IvyJournal-Light"),
    url("./fonts/IvyJournal/IvyJournal-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
