@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.heroContainer-wrapper {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.heroContainer-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 1.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0, 50% 60%;
  width: 100%;
  @media (max-width: $BREAKPOINT_TABLET) {
    height: 18rem;
  }

  @media (min-width: $BREAKPOINT_TABLET) {
    height: 21rem;
  }

  @media (min-width: $BREAKPOINT_DESKTOP_S) {
    height: 16rem;
  }

  @media (min-width: $BREAKPOINT_DESKTOP_M) {
    height: 21rem;
  }
}

.heroContainer-logo {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 6.25rem;
  width: 8.625rem;

  @media (min-width: $BREAKPOINT_DESKTOP_S) {
    width: 12.5rem;
    height: 9rem;
  }
}

.heroContainer-par {
  color: white;
  text-align: center;
  font-family: Lato;
  font-size: $SMALL_LABEL_FONT;
  font-style: normal;
  font-weight: $MEDIUM;
  line-height: 1.5rem;
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 64px;
  justify-content: center;
  @media screen and (max-width: 479px) {
    margin-top: 32px;
  }
}

.button-login {
  display: inline-block;
  width: 250px;
  height: auto;
  max-width: 90%;
  padding: 9px 15px 9px 20px;
  border: 1px solid #fafafa;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
  transition: all 700ms ease;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 5px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    border-color: #000;
    background-color: #fff;
    color: #000;
  }
}

.heroContainer-title-wrapper {
  background-color: #efebf3;
  padding: 2rem;
  border-radius: 0.25rem;

  .heroContainer-title {
    margin-top: 0;
    font-size: 2.25rem;
    color: $TITLE_COLOR;
    font-family: "Ivy Journal";
    font-style: normal;
    font-weight: $LIGHT;
    line-height: 3rem;
    margin-bottom: 2.5rem;
  }
  .hereContainer-subtitle-wrapper {
    p {
      font-size: 1.125rem;
      font-weight: $MEDIUM;
      margin: 0;
      color: $TEXT_COLOR;
      font-family: $DEFAULT_FONT;
    }
  }
}
