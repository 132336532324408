.activate-account {
  &-completed {
    .login-wrapper-header {
      .tecma-icon {
        height: 3rem;
        width: 3rem;
        path {
          fill: #2e872b;
        }
      }
    }
  }
  .login-wrapper-content {
    .login-wrapper-content-input {
      .validators {
        margin-bottom: 0;
      }
    }
    .radioButton-container {
      &.checked {
        padding: 0.125rem;
      }
    }
    .tecma-checkbox-container.checked.small {
      .checkbox-element {
        border: 0;
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
