@import "../../styles/variables.scss";

.bss-tools-container {
  justify-content: flex-start;
  @media (min-width: 550px) and (max-width: 767px) {
    .MuiGrid-grid-xs-12 {
      max-width: 50%;
    }
  }
}
