@import "styles/colors.scss";
@import "styles/variables.scss";

.right-half-registration {
  display: flex;
  position: absolute;
  right: 0px;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  min-height: 600px;
  height: 100%;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    width: 100%;
    height: auto;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 195px;
}

.registration-margin-bottom {
  margin-bottom: 15px;
}

.registration-icon {
  width: $LOGO_WIDTH_HUGE;
  margin-top: 10px;
  margin-bottom: 40px;
}

.registration-button {
  min-width: 120px !important;
}

.registration-logo-vendor {
  width: $LOGO_WIDTH_HUGE;
  margin-bottom: 10px;
}

.registration-title {
  color: black;
  letter-spacing: 3px;
  font-size: 28px;
}

.registration-hint {
  color: $GREY_TEXT;
  font-size: 13px;
}

.left-half-registration {
  width: 50%;
  position: absolute;
  left: 0px;
  object-fit: cover;
  height: 100%;
  // border-right: 1px solid black;
}
