.forgot-password {
  .login-wrapper-header {
    .tecma-icon {
      height: 3rem;
      width: 3rem;
      path {
        fill: #2e872b;
      }
    }
  }
  .login-wrapper-content {
    .login-wrapper-content {
      gap: 1rem;
      .tecma-button.primary {
        margin-top: 0.5rem;
      }
      .tecma-button.transparent {
        color: #6266ef;
      }
    }
  }
}
