@import "styles/colors.scss";
@import "styles/variables.scss";

.support-alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);

  .support-alert-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 2.5rem;
    background-color: $COLOR_SECONDARY;
    width: 100%;
    max-width: 38.75rem;

    .support-alert {
      &-close {
        position: absolute;
        top: 0;
        right: 0;
      }

      &-header {
        &-warning,
        &-error,
        &-success {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          p,
          h2 {
            font-family: $DEFAULT_FONT;
            font-weight: $BOLD;
            margin: 0;
          }

          p {
            font-size: 16px;
            text-transform: uppercase;
          }

          h2 {
            font-size: $REGULAR;
          }
        }

        &-error {
          color: $ALERT_ERROR;
        }
        &-success {
          color: $ALERT_SUCCESS;

          span {
            color: $COLOR_PRIMARY;
          }
          .ticketNumber {
            font-family: $DEFAULT_FONT;
            color: $COLOR_PRIMARY;
            text-transform: none;
            font-weight: $REGULAR;
          }
        }
      }

      &-content {
        &-error {
          font-family: $DEFAULT_FONT;

          .support-email {
            font-family: $DEFAULT_FONT;
            font-weight: $SEMI_BOLD;
            color: $DEFAULT_FONT_COLOR;
            font-style: italic;
            margin-top: -1.2rem;
          }
        }
        margin-top: 0.5rem;
        p {
          font-family: $DEFAULT_FONT;

          text-align: center;
          font-size: 1rem;
          color: $EVENT_DATA_COLOR;

          span {
            font-family: $DEFAULT_FONT;

            font-weight: $BOLD;
          }
        }
      }

      &-footer {
        &-warning,
        &-error,
        &-success {
          display: flex;
          justify-content: center;
          gap: 0.625rem;
          margin-top: 5.375rem;

          button {
            margin: 0;
            height: 2.5rem;
            width: 5rem;
            border-radius: 2px;

            span {
              font-family: $DEFAULT_FONT;
              font-size: $SMALL_LABEL_FONT;
              text-transform: capitalize;
            }
          }
        }

        &-warning {
          button {
            &.MuiButton-outlinedPrimary {
              color: $COLOR_PRIMARY;
              border-color: $COLOR_PRIMARY;
            }
          }
        }
        &-error {
          button {
            &.MuiButton-outlinedPrimary {
              color: $ALERT_ERROR;
              border-color: $ALERT_ERROR;
            }
          }
        }
        &-success {
          button {
            &.MuiButton-outlinedPrimary {
              color: $ALERT_SUCCESS;
              border-color: $ALERT_SUCCESS;
            }
          }
        }
      }
    }
  }
}
