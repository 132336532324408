@import "styles/colors.scss";
@import "styles/variables.scss";

.tecma-modal-container {
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.support-modal-content {
  display: flex;
  max-height: 48vh;
  padding: 1rem 0.5rem 0 1.5rem;
  overflow: auto;
  border-right: solid #fff 0.5rem;
  width: 100%;
  .divider {
    background-color: #6266ef;
    width: 100%;
    height: 1px;
    border-radius: 20px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  p.mandatory-field {
    margin: 0;
    color: #767676;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;

    &-invalid {
      color: $FORM_ERROR;
      font-weight: $BOLD;
    }
  }

  .user-info {
    text-transform: uppercase;
    color: #3b3b3b;
    font-family: Lato, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;

    span:not(:first-child) {
      margin-left: 0.5rem;
    }

    &-name {
      text-transform: none;
    }
    &-role {
      color: #767676;
      text-transform: none;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2,
    p {
      margin: 0;
    }
    h2 {
      color: #3b3b3b;
      font-family: Lato;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2rem;
    }
    .ticket-info {
      margin: 0;
      color: #767676;
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  input,
  textarea {
    &::placeholder {
      font-weight: 400;
      color: #a8a8a8;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    background: $SCROLLBAR_BACK;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color: $SCROLLBAR_BODY, $alpha: 0.5);
    border-radius: 100px;
  }

  .section-content {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    .tecma-select-box {
      width: unset;
      min-width: 17rem;
    }
    .form-value-description {
      flex: 1;
    }
    &.two-col {
      > div {
        flex: 1;
        max-width: calc(100% / 2 - 0.5rem);
        @media screen and (max-width: $BREAKPOINT_TABLET) {
          max-width: unset;
        }
      }
      @media screen and (max-width: $BREAKPOINT_TABLET) {
        flex-direction: column;
      }
    }
  }
  &-section {
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      font-size: 1rem;
      font-weight: $BOLD;
      margin: 0;
      color: $DARK-TEXT;
    }
    .mandatory-field {
      color: #767676;
      font-family: Lato, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }

  .error-message {
    color: $FORM_ERROR;
    font-size: $SMALL_FONT;
    font-weight: $REGULAR;
    height: 0.875rem;
    margin: 0;
    margin-top: 0.125rem;
    opacity: 0;
    transition: opacity 300ms;

    &.visible {
      opacity: 1;
    }
  }

  &-subject {
    &-label {
      font-family: $DEFAULT_FONT;
      font-weight: $BOLD;
      font-size: $SMALL_LABEL_FONT;
      color: $TEXT_GREY;
    }
  }

  &-description {
    &-title {
      color: $TEXT_GREY;
      font-weight: $BOLD;
    }

    &-label {
      color: $TEXT_GREY;
    }
  }

  .textarea-wrapper {
    flex-direction: column;
    padding-bottom: 1rem;
    textarea {
      font-size: $SMALL_LABEL_FONT;
      font-family: $DEFAULT_FONT;
      padding: 0.5rem 0.625rem;
      border: 1px solid $DROPZONE_BORDER;
      color: $TEXT_GREY;
      resize: vertical;
      min-height: 8rem;

      &.error {
        color: $FORM_ERROR;

        &:focus-visible {
          outline-color: $FORM_ERROR;
        }
      }
    }
    .characters-length {
      color: $TEXT_GREY;
      font-size: $SMALL_FONT;
      font-weight: $LIGHT;
      height: 0.875rem;
      margin: 0;

      &.subject {
        opacity: 0;
        color: $DARK-TEXT;
        font-weight: $REGULAR;
        transition: opacity 300ms;

        &.visible {
          opacity: 1;
        }

        &.error {
          opacity: 1;
          color: $FORM_ERROR;
        }
      }

      &.description {
        &.warning {
          color: $DARK-TEXT;
          font-weight: $REGULAR;
        }

        &.error {
          color: $FORM_ERROR;
          font-weight: $REGULAR;
        }
      }
    }

    &.field-invalid {
      .support-form-description-title,
      .characters-length {
        color: $FORM_ERROR;
        opacity: 1;

        &.subject,
        &.description {
          color: $FORM_ERROR;
          font-weight: $REGULAR;
        }
      }

      input,
      textarea {
        border: 2px solid $FORM_ERROR;
      }
    }
  }
}

.support-modal-footer {
  @media screen and (max-width: $BREAKPOINT_TABLET) {
    flex-direction: column;
  }
}
