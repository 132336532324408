.change-password {
  &-completed {
    .login-wrapper-header {
      .tecma-icon {
        height: 3rem;
        width: 3rem;
        path {
          fill: #2e872b;
        }
      }
    }
  }
}
