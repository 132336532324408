@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.bss-toolBox {
  font-family: $DEFAULT_FONT;
  height: 100%;
  border-radius: 0.25rem;
  border: 1px solid $BORDER_AVATAR;
  box-shadow: 2px 2px 8px 4px rgba(223, 225, 230, 0.25);
  .tecma-card-media {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    img {
      height: fit-content;
      object-position: center;
    }
  }
  .toolBox-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .toolBox-header {
      font-size: 1rem;
      font-weight: $SEMI_BOLD;
      color: $TITLE_COLOR;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .toolBox-content {
      color: $TEXT_COLOR;
      font-size: $SMALL_LABEL_FONT;
      line-height: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      flex-grow: 1;
      p {
        margin: 0;
      }
    }
  }
  .toolBox-footer {
    border-top: 0.0625rem solid #e5e5e5;
  }
}
