@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &.rotate {
    transform: rotate(180deg);
  }
  @media (min-width: $BREAKPOINT_TABLET) {
    align-items: unset;
  }
  &-background {
    position: absolute;
    left: 0px;
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-height: 38.5rem;
    @media (max-width: $BREAKPOINT_TABLET) {
      display: none;
    }
    @media (min-width: $BREAKPOINT_TABLET) {
      min-height: 42.5rem;
    }
    @media (min-width: $BREAKPOINT_DESKTOP_M) {
      min-height: 47.75rem;
    }
  }
  &-container {
    position: relative;
    z-index: 1;
    background: white;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 0.25rem;
    max-width: 32rem;
    margin: auto;
    @media (min-width: $BREAKPOINT_TABLET) {
      height: fit-content;
    }
    @media (min-width: $BREAKPOINT_TABLET) and (max-height: 42.5rem) {
      margin: 2rem auto;
    }

    @media (min-width: $BREAKPOINT_DESKTOP_M) {
      display: flex;
      flex-direction: column;
      min-height: 43.75rem;
    }
    @media (min-width: $BREAKPOINT_DESKTOP_M) and (max-height: 47.75rem) {
      margin: 2rem auto;
    }

    .login-wrapper-content {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 100%;
      max-width: 20rem;
      box-shadow: none;
      gap: 2rem;
      padding: 3.5rem 1.5rem 0;
      @media (max-width: $BREAKPOINT_TABLET) {
        height: 100%;
      }

      &-loading {
        align-items: center;
        justify-content: space-between;

        .tecma-spinner {
          margin-top: 6rem;
        }
      }

      @media (min-width: $BREAKPOINT_TABLET) {
        padding: 2.5rem 0;
      }
      @media (min-width: $BREAKPOINT_DESKTOP_M) {
        flex: 1;
      }

      .login-wrapper-header {
        display: flex;
        padding: 0 0 0.5rem 0;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        &-title {
          color: #3b3b3b;
          font-family: Lato;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2rem;
          text-align: center;
        }
        &-subtitle {
          color: #767676;
          text-align: center;
          font-family: Lato;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
        }
      }
      .login-wrapper-content {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        height: fit-content;
        overflow: initial;
        margin: 0 auto;

        .tecma-alert {
          font-family: Lato;
          min-width: initial;
          box-sizing: border-box;
          width: 100%;
          .alert-title {
            font-weight: 500;
          }
          .tecma-icon {
            margin: auto;
          }
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          gap: 1.5rem;
        }
        &-input {
          width: 100%;
          .input-label {
            color: #3b3b3b;
          }
          .validators {
            .validator {
              &.validator-ok {
                color: #2e872b;
                path {
                  fill: #2e872b;
                }
              }
            }
          }
        }
        &-icon {
          height: 2.5rem;
          width: 2.5rem;
          margin: auto;
        }
        &-text {
          color: #767676;
          text-align: center;
          font-family: Lato;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
        }
        &-link {
          color: #6266ef;
          font-family: Lato;
          font-size: 0.875rem;
        }
        .tecma-checkboxGroup {
          .tecma-checkbox {
            gap: 0.5rem;
            &.Mui-disabled {
              span {
                color: #9e9e9e;
              }
            }
          }
        }
        .tecma-radioGroup {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          label {
            font-size: 14px;
            color: #3b3b3b;
          }
        }
        &-checkbox {
          display: flex;
          align-items: center;
          &.disabled {
            span,
            a {
              color: #9e9e9e;
            }
          }
          span {
            color: #3b3b3b;
            text-align: left;
          }
        }
        .tecma-button {
          gap: 0.5rem;
          .tecma-spinner {
            border-left-color: #fff;
          }
        }
      }
      .login-wrapper-container-footer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $BREAKPOINT_TABLET) {
          flex: 1;
          padding-bottom: 2rem;
        }

        @media (min-width: $BREAKPOINT_DESKTOP_M) {
          justify-content: flex-end;
        }
        .tecma-lazyImage {
          width: 4.5rem;
          height: 4.5rem;
          object-fit: contain;
        }

        #language-selector-button {
          background-color: transparent;
          color: #3b3b3b;
          gap: 0.5rem;
          box-shadow: none;
          width: 11.25rem;
          .tecma-icon {
            path {
              fill: #3b3b3b;
            }
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 1rem 1rem 2.5rem;

    @media (min-width: $BREAKPOINT_DESKTOP_M) {
      padding: 1rem;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    }

    &-powered-by {
      width: fit-content;
      background-color: rgba($color: #fff, $alpha: 0.6);
      backdrop-filter: blur(2px);
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      display: none;
      @media (min-width: $BREAKPOINT_DESKTOP_M) {
        display: flex;
      }
      &-text {
        color: #3b3b3b;
        font-family: Lato;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
      }
      .tecma-lazyImage {
        width: 5.5rem;
      }
    }

    &-right {
      display: flex;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      @media (min-width: $BREAKPOINT_DESKTOP_M) {
        width: auto;
      }
      &__desk-action:not(.dropDown-trigger) {
        outline: none;
        &.keyboard-active {
          background-color: #e0e0e0 !important;
        }
        @media (max-width: 1919px) {
          display: none;
        }
      }
      .dropDown-trigger {
        color: #3b3b3b;
        border-color: #e0e0e0;
        min-width: 8.25rem;
        .tecma-icon {
          path {
            fill: #3b3b3b;
          }
        }
        @media (min-width: $BREAKPOINT_DESKTOP_M) {
          border-color: #f1f1f1;
          color: #fff;
          .tecma-icon {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  &-keyboard {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    padding: 0.5rem;
    width: 43rem;
    background-color: #fff;
    transform: translateX(-50%);
    @media (max-width: 1919px) {
      display: none;
    }
  }
}
.tecma-dropDown.language-selector-dropdown {
  max-height: 15.625rem;
}
