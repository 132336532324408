@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.footer {
  padding: 1rem;
  background-color: white;
  width: 100%;
  text-align: center;
  border-top: 1px solid $BORDER_AVATAR;
}

.footer-text {
  font-family: Lato;
  color: $TEXT_COLOR;
  font-size: $SMALL_FONT;
  font-style: normal;
  font-weight: $MEDIUM;
  line-height: 1rem;
}
