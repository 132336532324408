@import "../../styles/variables.scss";

.bss-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  .home-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 4.5rem;
    padding: 0 1.5rem;
    gap: 1.5rem;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    @media (min-width: $BREAKPOINT_TABLET) {
      padding: 1.5rem 1.5rem;
    }

    @media (min-width: $BREAKPOINT_DESKTOP_S) {
      padding: 2.5rem 3.5rem;
    }

    @media (min-width: $BREAKPOINT_DESKTOP_M) {
      padding: 2.5rem 11.5rem;
    }

    @media (min-width: $BREAKPOINT_DESKTOP_L) {
      padding: 2.5rem 13rem;
    }
    @media (min-width: $BREAKPOINT_DESKTOP_XL) {
      padding: 2.5rem 0;
      width: 81rem;
    }
    .home-spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
