@import "styles/colors.scss";
@import "styles/variables.scss";
@import "styles/main.scss";

.filled {
  .input-default {
    color: $EDITING_COLOR !important;
  }
  .small-label-font {
    color: $EDITING_COLOR !important;
    font-size: $SMALL_LABEL_FONT;
  }
}

.input-default {
  @extend .bold;
  text-overflow: ellipsis;
  font-size: $BIGGER_FONT !important;
}

.input-table-filter {
  @extend .bold;
  text-overflow: ellipsis;
  //font-size: $BIGGER_FONT!important;
}

.MuiInputBase-input {
  border-bottom-color: $BACKGROUNDS;
}

.MuiInput-underline {
  &.Mui-disabled:before,
  &:before,
  &:hover:not(.Mui-disabled):before {
    border-bottom-style: solid;
    border-bottom-color: $BACKGROUNDS;
  }
}

.client-form-spacing .MuiInput-underline {
  &.Mui-disabled:before,
  &:before,
  &:hover:not(.Mui-disabled):before {
    border-bottom-style: solid;
    border-bottom-color: $DISABLED;
  }
}

.drop-zone-container {
  border: 2px dashed $DROPZONE_BORDER;
  flex-grow: 1;
  max-height: 45px;
  padding: 10px 20px;
  border-radius: 20px;
  margin-right: 42px;
}

.file-input-bin-placeholder {
  width: 42px;
}

.positioned-file-input-image {
  z-index: 1;
  width: 15px;
  top: 88px;
  right: 50px;
  height: 15px;
  position: absolute;
}

.error-text-file-input {
  color: $ERROR_COLOR;
}

.very-small-font {
  font-size: $VERY_SMALL_FONT;
  color: $ERROR_COLOR;
  font-weight: $BOLD;
}
